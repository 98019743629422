import  Stack  from '@mui/material/Stack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useCookies } from 'react-cookie';
import server from '../services/server';

export default function AuthRedirect() {
    const [params] = useSearchParams();
    const [cookies, setCookies ] = useCookies(['token', 'user']);
    const navigation = useNavigate();
    const { Login } = useAuth();
    
    const Authentication = async token => {
        Login(params.get('token'));
        const { data } = await server.get('profile', {
            headers: {
                Authorization: `Bearer ${params.get('token')}`,
            },
        });
        setCookies('user', data.data, { path: '/' });
        navigation('/dashboard', {replace: true})
    };
    
    useEffect(() => {
        Authentication();
    }, []);

    return (
        <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
            Carregando Dados
        </Stack>
    );
}
