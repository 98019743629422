import LogoGif from '../assets/logo.gif'
import { Box, Dialog, Stack } from '@mui/material';
export function LoadingContent() {
    return (
        <Dialog open>
            <Stack alignItems="center" width="100vw" height="100vh" justifyContent="center">
                <Box component="img" width={100} height={100} src={LogoGif} />
            </Stack>
        </Dialog>
    );
}
