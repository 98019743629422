import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
 
export default function SelectCategories({ value, name, onChange, placeholder, label }) {
    
    const { data, mutate, error, isFetching } = useFetch(`category`, {params:{status: true, all: true}});
    
    // const [options, setOptions] = useState([]);
    // useEffect(() => {
    //     if (data && data.rows) {
    //         setOptions(prev => {
    //             const combinedOptions = [...prev, ...data.rows];
    //             const uniqueOptions = combinedOptions.reduce((acc, current) => {
    //                 if (!acc.some(item => item.id === current.id)) {
    //                     acc.push(current);
    //                 }
    //                 return acc;
    //             }, []);
    //             return uniqueOptions;
    //         });
    //     }
    // }, [data]);

    const handleSelect = (selected)=>{
        onChange(name, selected.map( i=>i.id) )
    }

    const selected = data?.filter( item=> value && value.includes(item.id))  
    
    return (
        <div>
            <Autocomplete
                placeholder={placeholder}
                getOptionLabel={e => e?.name}
                getOptionKey={e=>e.id}
                options={data || []}
                value={selected}
                name={name}
                multiple
                loading={isFetching}
                onChange={(e, newValue) => handleSelect(newValue)}
                renderInput={(params) => <TextField {...params} label={placeholder || label} />}
            />
            {!!error?.message && <FormHelperText>{error?.message}</FormHelperText>}
        </div>
    );
}
