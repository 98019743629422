import { forwardRef, useImperativeHandle, useState } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import { useFetch } from '../../hooks/useFecth';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircle from '@mui/icons-material/AddCircle';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import DialogPlans from './components/DialogPlan';
import { ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

export default forwardRef(function Plans(props, ref) {
    const [dialog, setDialog] = useState(false);
    const { data, error, mutate, isFetching } = useFetch('products', { params: { type: 'plan', clubId: null } });

    const [selected, setSelected] = useState();

    const handleCloseDialog = () => {
        setDialog(false);
        setSelected();
    };

    const handleOpenDialog = item => {
        setDialog(true);
        setSelected(item);
    };

    useImperativeHandle(ref, () => {
        return {
            open() {
                setDialog(true);
            },
            close() {
                handleCloseDialog();
            },
        };
    });

    return (
        <Container>
            <Box py={5}>
                <Typography level="h2">Planos</Typography>
            </Box>
            <Card>
                <List orientation="horizontal" sx={{ gap: 1 }}>
                    <ListItem>
                        <ListItemButton component={Link} to="#plans">
                            <ListItemText>Adicionar plano</ListItemText>
                            <ListItemIcon>
                                <AddCircle />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />

                <Grid container columnGap={2}>
                    <Grid item xs>
                        <List size="md" sx={{ gap: 1 }}>
                            {data?.rows?.map(item => (
                                <>
                                    <ListItem key={`list_${item.id}`}>
                                        <ListItemButton component={Link} to={`#plans|${item.id}`}>
                                            <ListItemText>{item?.name}</ListItemText>
                                            <ListItemIcon></ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Card>
            <DialogPlans mutate={mutate} />
        </Container>
    );
});
