import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import { useFetch } from '../../hooks/useFecth';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircle from '@mui/icons-material/AddCircle';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import DialogUser from './components/DialogUser';
import { Link } from 'react-router-dom';
import { Button, ListItemText, Stack } from '@mui/material';
import React from 'react';

export default function User() {
    const { data, error, mutate, isFetching } = useFetch('users');

    return (
        <Container>
            <Box py={5}>
                <Typography variant="h2">Usuários</Typography>
            </Box>
            <Card>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" columnGap={2} p={2}>
                    <Button variant="outlined" component={Link} to={`#users`}>Adicionar usuário</Button>
                </Stack>
                <Divider />
                <Grid container columnGap={2}>
                    <Grid item xs>
                        <List size="md" sx={{ gap: 1 }}>
                            {data?.rows?.map(item => (
                                <React.Fragment key={`list_${item.id}`}>
                                    <ListItem>
                                        <ListItemButton component={Link} to={`#users|${item.id}`}>
                                            <ListItemText>{item?.userName}</ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Card>
            <DialogUser mutate={mutate} />
        </Container>
    );
}
