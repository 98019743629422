import { Card, CardContent, CardHeader, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { useFetch } from '../hooks/useFecth';

export default function Dashboard() {
    const { data } = useFetch(`dashboard`);

    return (
        <Container sx={{my:4}}>
            <Stack py={3}>
                <Typography variant='h1'>Dashboard</Typography>
            </Stack>
            <Paper sx={{ p: 2 }}>
                
                <Grid container spacing={2}>
                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.rescuesPendent || 0}</Typography>
                            </CardContent>
                            <Divider />
                            <CardHeader title="Resgates pendentes" />
                        </Card>
                    </Grid>

                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.rescuesConcluded || 0}</Typography>
                            </CardContent>
                            <Divider />
                            <CardHeader title="Resgates concluídos" />
                        </Card>
                    </Grid>

                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.cashbackPendent || 0}</Typography>
                            </CardContent>
                            <Divider />
                            <CardHeader title="Cashback pendente"></CardHeader>
                        </Card>
                    </Grid>

                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.cashbackRescued || 0}</Typography>
                            </CardContent>
                            <Divider />
                            <CardHeader title="Cashback resgatado" />
                        </Card>
                    </Grid>

                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.subscriber}</Typography>
                            </CardContent>
                            <Divider/>
                            <CardHeader title="Assinantes ativos" />
                        </Card>
                    </Grid>

                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.subscriberInactive}</Typography>
                            </CardContent>
                            <Divider/>
                            <CardHeader title="Assinantes inativos" />
                        </Card>
                    </Grid>

                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.dependent}</Typography>
                            </CardContent>
                            <Divider/>
                            <CardHeader title="Dependentes ativos" />
                        </Card>
                    </Grid>

                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.dependentInactive}</Typography>
                            </CardContent>
                            <Divider/>
                            <CardHeader title="Dependentes inativos" />
                        </Card>
                    </Grid>

                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.club}</Typography>
                            </CardContent>
                            <Divider/>
                            <CardHeader title="Clubes ativos" />
                        </Card>
                    </Grid>

                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.clubInactive}</Typography>
                            </CardContent>
                            <Divider/>
                            <CardHeader title="Clubes inativos" />
                        </Card>
                    </Grid>
                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.advertiser || 0}</Typography>
                            </CardContent>
                            <Divider/>
                            <CardHeader title="Anunciantes ativos" />
                        </Card>
                    </Grid>

                    <Grid item xs md={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="title-lg">{data?.advertiserInactive || 0}</Typography>
                            </CardContent>
                            <Divider/>
                            <CardHeader title="Anunciantes inativos" />
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}
