import React, { createContext, useContext, useState } from 'react';
import Help from '@mui/icons-material/Help';
import { Box, DialogContent, DialogTitle, Divider, Paper, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';

const HelperSidebarContext = createContext({});

const helpers = {
    offer_rules:
        'As ofertas tem um campo de regras que pode ser preenchido com os detalhes a respeito de regras específicas do plano com texto plano.',
    offer_avaliableQty:
        'Você pode determinar a quantidade de ofertas disponíveis. Ao alcançar a quantidade disponível de resgates a oferta fica indisponível.',
    offer_urlOpenExternal:
        'O site da oferta pode ser aberto dentro do próprio app ou pode abrir no navegador do usuário. Algumas ofertas obrigatóriamente não permitem rastreamento abrindo dentro do app. Por isso selecione essa opção para esses casos.',
};

export default function HelpSidebarProvider(props) {
    const [open, setDialog] = useState();

    const handleCloseDialog = () => {
        setDialog();
    };

    const handleOpenDialog = item => {
        setDialog(helpers[item]);
    };

    return (
        <HelperSidebarContext.Provider
            value={{
                openHelp: handleOpenDialog,
            }}
        >
            <>
                {props.children}
                <Drawer
                    sx={{ zIndex: 9999 }}
                    slotProps={{
                        content: {
                            sx: {
                                bgcolor: 'transparent',
                                p: { md: 3, sm: 0 },
                                boxShadow: 'none',
                                maxWidth: 350,
                            },
                        },
                    }}
                    role="presentation"
                    anchor="right"
                    variant="plain"
                    size="md"
                    open={Boolean(open)}
                    onClose={handleCloseDialog}
                    hideBackdrop
                >
                    <Paper sx={{ borderRadius: 'md', p: 2, height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <DialogTitle>
                            <Help /> Ajuda
                        </DialogTitle>
                        <Divider />

                        <DialogContent>
                            <Typography>{open}</Typography>
                        </DialogContent>
                    </Paper>
                </Drawer>
            </>
        </HelperSidebarContext.Provider>
    );
}

export const useHelper = () => {
    const context = useContext(HelperSidebarContext);
    if (!context) throw 'useApp context inside corret provider';

    return context;
};
