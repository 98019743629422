import React, { useState } from 'react';

const Dropdown = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // Função para abrir o menu ao clicar no botão
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    // Função para fechar o menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {/* O primeiro filho será o botão, por convenção */}
            {React.cloneElement(children[0], {
                'aria-controls': open ? 'dropdown-menu' : undefined,
                'aria-haspopup': 'true',
                'aria-expanded': open ? 'true' : undefined,
                onClick: handleClick,
            })}

            {/* O segundo filho será o Menu, com as opções */}
            {React.cloneElement(children[1], {
                id: 'dropdown-menu',
                anchorEl: anchorEl,
                open: open,
                onClose: handleClose,
                MenuListProps: {
                    'aria-labelledby': 'basic-button',
                },
            })}
        </>
    );
};

export default Dropdown;
