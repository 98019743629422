import { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const TOGGLE_DIALOG = 'TOGGLE_DIALOG';
const OPEN_DIALOG = 'OPEN_DIALOG';
const CLOSE_DIALOG = 'CLOSE_DIALOG';

const dialogReducer = (state, action) => {
    switch (action.type) {
        case TOGGLE_DIALOG:
            return { isOpen: !state.isOpen };
        case OPEN_DIALOG:
            return { isOpen: true };
        case CLOSE_DIALOG:
            return { isOpen: false };
        default:
            return state;
    }
};

export const useFilter = key => {
    // Carregar o estado inicial do sessionStorage
    const getInitialState = () => {
        const storedState = sessionStorage.getItem(key);
        return storedState ? JSON.parse(storedState) : { isOpen: false };
    };

    const [isFilter, setSearch] = useSearchParams();

    const [state, dispatch] = useReducer(dialogReducer, getInitialState());

    // Sincronizar o estado com o sessionStorage sempre que o estado mudar
    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(state));
    }, [state, key]);

    // Funções para abrir, fechar e alternar o diálogo
    const openDialog = () => dispatch({ type: OPEN_DIALOG });
    const closeDialog = () => dispatch({ type: CLOSE_DIALOG });
    const toggleDialog = () => dispatch({ type: TOGGLE_DIALOG });

    const cleanFilter = () => {
        setSearch();
    };

    return {
        isOpen: state.isOpen,
        isFilter: !!isFilter.size,
        openDialog,
        closeDialog,
        toggleDialog,
        cleanFilter,
    };
};

export default function useDialog(dialog) {
    const location = useLocation();
    const hash = location?.hash?.split('|');
    const navigate = useNavigate();

    const handleClose = () => {
        navigate(-1, { replace: true });
    };

    const handleOpen = (open)=>{
        navigate(open, { replace: true });
    }
    return { id: hash[1] || null, open: dialog === hash[0],  name: hash[0], handleClose, handleOpen};
}
