import { Routes, Route, Outlet } from 'react-router-dom';
import Auth from './modules/Auth';
import AuthRedirect from './modules/AuthRedirect';
import Advertiser from './modules/Advertiser';
import Category from './modules/Category';
import Team from './modules/Team';
import User from './modules/User';
import Plans from './modules/Plans';
import Aditionals from './modules/Aditionals';
import AdvertiserDetails from './modules/Advertiser/AdvertiserDetails';
import Club from './modules/Club';
import ClubeDetails from './modules/Club/ClubDetails';
import Protected from './modules/Protected';
import Dashboard from './modules/Dashboard';

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Auth />} />
            <Route path="/auth" element={<AuthRedirect />} />
            <Route path="/dashboard" element={<Protected />}>
                <Route index element={<Dashboard />} />
                <Route path="configuracoes" element={<Outlet />}>
                    <Route path="cargos" element={<Team />} />
                    <Route path="usuarios" element={<User />} />
                    <Route path="categorias" element={<Category />} />
                    <Route path="planos" element={<Plans />} />
                    <Route path="adicionais" element={<Aditionals />} />
                </Route>
                <Route path="anunciantes" element={<Outlet />}>
                    <Route index element={<Advertiser />} />
                    <Route path=":id" element={<AdvertiserDetails />} />
                </Route>
                <Route path="clubes" element={<Outlet />}>
                    <Route index element={<Club />} />
                    <Route path=":id" element={<ClubeDetails />} />
                </Route>
            </Route>
            {/*
            <Route path="/criar-conta" element={<Register />} />
            <Route path="/p/:token" element={<Colelct />} />

            <Route path="/nova-organizacao" element={<AddOrganization />} />
            <Route path="/escolha-plano" element={<AddPlan />} />

            <Route path="/dashboard" element={<Dashboard />}>
                <Route index element={<Home />} />
                <Route path='contatos' element={<Contacts />} />
                <Route path="nova-pesquisa" element={<AddQuestion />} />
                <Route path="pesquisa/:id" element={<EditQuestion />} />
                <Route path='questionarios' element={<ListQuestions />} />
                <Route path='questionarios/:id' element={<ViewQuestion/>} />
            </Route> */}
        </Routes>
    );
}
