import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import { useFetch } from '../../hooks/useFecth';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircle from '@mui/icons-material/AddCircle';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import DialogPlans from './components/DialogAditional';
import { Button, ListItemText, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Aditionals(props, ref) {
    const { data, error, mutate, isFetching } = useFetch('products', { params: { type: 'additional', clubId: null } });

    return (
        <Container>
            <Box py={5}>
                <Typography variant="h2">Adicionais</Typography>
            </Box>
            <Card>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" p={2}>
                    <Button variant="outlined" component={Link} to="#additional">
                        Adicionar produto
                    </Button>
                </Stack>
                <Divider />
                <Grid container columnGap={2}>
                    <Grid item xs>
                        <List size="md" sx={{ gap: 1 }}>
                            {data?.rows?.map(item => (
                                <React.Fragment key={`list_${item.id}`}>
                                    <ListItem>
                                        <ListItemButton component={Link} to={`#additional|${item.id}`}>
                                            <ListItemText primary={item?.name} />
                                            <ListItemIcon></ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Card>
            <DialogPlans mutate={mutate} />
        </Container>
    );
}
