import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import useErrors from '../../../hooks/useErrors';
import server from '../../../services/server';
import { useSnackbar } from 'notistack';
import SelectCategory from '../../../components/SelectCategory';
import { useFetch } from '../../../hooks/useFecth';
import { Dialog, Divider } from '@mui/material';
import useDialog from '../../../hooks/useDialog';
import AddImage from '../../../components/AddImage';
import { useNavigate } from 'react-router-dom';

function Edit({ id, mutate }) {
    const { data } = useFetch(`products/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const { open, handleClose } = useDialog('#plans');
    const setErrs = useErrors();
    const navigation = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const { values, handleSubmit, setValues, handleChange, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            description: '',
            type: 'plan',
            additionalType: '',
            price: '',
            disccount: '',
            numberLifes: '',
            image: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    const { data } = await server.post(`products`, values);
                    enqueueSnackbar(`Plano ${values.name} adicionado`);
                    navigation(`#plans|${data.data.id}`);
                }

                if (Boolean(values.id)) {
                    console.log(values);
                    setSubmitting(true);
                    await server.put(`products/${values.id}`, values);
                    enqueueSnackbar(`Plano ${values.name} alterado`);
                    handleClose();
                }

                await mutate();
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            console.log(data);
            setValues(data, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{Boolean(data) ? 'Editando' : 'Adicionando'}</DialogTitle>
            <Divider />
            {/* <Typography>Vamos adicionar uma nova {Boolean(childOf) ? `subcategoria em ${childOf.name}` : 'categoria'}</Typography> */}
            <DialogContent component="form" onSubmit={handleSubmit}>
                <Stack rowGap={2}>
                    {values.id && (
                        <AddImage value={values.previewImage || values.image} onChange={setFieldValue} name="image" label="Adicionar imagem" />
                    )}
                    <TextField onChange={handleChange} label={`Nome do plano`} value={values.name} name="name" />
                    <TextField onChange={handleChange} label={`Descriçãod o plano`} value={values.description} name="description" />
                    {values.id && (
                        <>
                            <TextField onChange={handleChange} label="Preço" value={values.price} name="price" />
                            <TextField onChange={handleChange} label="N. de vidas" value={values.numberLifes} name="numberLifes" />
                        </>
                    )}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" loading={isSubmitting} onClick={handleSubmit} type="submit">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function DialogPlans({ mutate }) {
    const { open, id } = useDialog('#plans');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
