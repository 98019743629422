 
export const centered = data => {
    const listaLat = data.map(item => item.rastreamento.split(',')[0]).sort();
    const listaLng = data.map(item => item.rastreamento.split(',')[1]).sort();

    const minLat = Number(listaLat[0]);
    const maxLat = Number(listaLat[listaLat.length - 1]);
    const minLng = Number(listaLng[0]);
    const maxLng = Number(listaLng[listaLng.length - 1]);

    return { lat: minLat + (maxLat - minLat) * 0.5, lng: minLng + (maxLng - minLng) * 0.5 };
};

export const languages = ['english', 'spanish', 'portuguese', 'french'];

export const bytesToSize = bytes => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const channel = {
    virtual: 'Virtual',
    physical: 'Físico'
}

//DRAFT //ANALYSE //WAITING //IN_PROGRESS //FINISHED //CONCLUDED //CANCELED //DISAPPROVED //PAYMENT_FAIL
export const status = {
    DRAFT: 'RASCUNHO',
    ANALYSE: 'PARA ANÁLISE',
    WAITING: 'AGUARDANDO ACEITE',
    IN_PROGRESS: 'EM PROGRESSO',
    FINISHED: 'FINALIZADO',
    CANCELED: 'CANCELADO',
    DISAPPROVED: 'REPROVADA',
    CONCLUDED: 'CONCLUIDO',
    PAYMENT_FAIL: 'PAGAMENTO FALHOU',

    PAID: 'PAGO',
    DELIVERED: 'ENTREGUE',
    COLLECTED: 'COLETADO',
    ACCEPTED: 'COLETA ACEITA',
    PAYMENT_SET: 'PAGAMENTO CONFIGURADO',
};

export const orderStatus = {
    WAITING: 'AGUARDANDO',
    IN_PROGRESS: 'EM PROGRESSO',
    FINISHED: 'FINALIZADO',
    CONCLUDED: 'ENTREGUE',
    ORDER_CANCELED: 'CANCELADO',
};

export const saleStatus = {
    DRAFT: 'SEM PAGAMENTO',
    CLOSED: 'FECHADA',
    CANCELED: 'CANCELADO',
    CONCLUDED: 'PAGO',
    REJECTED: 'REJEITADO',
};

export const payments = {
    credit_card: 'Cartão de Crédito',
    pix: 'PIX',
    cash: 'Dinheiro',
    credit_card_installments: 'Cartão de Crédito - Parcelado',
    debit_card: 'Cartão de Débito',
};

export const listHours = (day, horaInicial = 0, horaFinal = 23, intervaloMinutos = 60) => {
    const arrayDeHoras = [];

    day = day || new Date();

    for (let hora = horaInicial; hora <= horaFinal; hora++) {
        for (let minuto = 0; minuto < 60; minuto += intervaloMinutos) {
            //const horaFormatada = `${hora.toString().padStart(2, '0')}:${minuto.toString().padStart(2, '0')}`;
            const horaFormatada = new Date(day.getFullYear(), day.getMonth(), day.getDate(), hora, minuto);

            arrayDeHoras.push(horaFormatada);
        }
    }

    return arrayDeHoras;
};

export const pluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

export function sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time || 500));
}

export const nps = [
    { color: '#ff6666', value: 1 },
    { color: '#ff6666', value: 2 },
    { color: '#ff6666', value: 3 },
    { color: '#ff6666', value: 4 },
    { color: '#ff6666', value: 5 },
    { color: '#fed238', value: 6 },
    { color: '#fed238', value: 7 },
    { color: '#fed238', value: 8 },
    { color: '#B0CC17', value: 9 },
    { color: '#B0CC17', value: 10 },
];

export const csat = [
    { color: '#48CAE4', value: 1, emoji: '😭' },
    { color: '#48CAE4', value: 2, emoji: '😞' },
    { color: '#48CAE4', value: 3, emoji: '😐' },
    { color: '#48CAE4', value: 4, emoji: '😊' },
    { color: '#48CAE4', value: 5, emoji: '😍' },
];

export const percentual = (a, b)=>{

    const calc = ((a * Number(100)) / Number(b)) || 0 

    return  Number(calc.toFixed(1))
}