import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import { useFetch } from '../../hooks/useFecth';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircle from '@mui/icons-material/AddCircle';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import DialogTeam from './components/DialogTeam';
import { Link } from 'react-router-dom';
import { ListItemText } from '@mui/material';

export default function Team() {
    const { data, error, mutate, isFetching } = useFetch('teams');

    const [selected, setSelected] = useState();

    return (
        <Container>
            <Box py={5}>
                <Typography variant="h2">Cargos</Typography>
            </Box>
            <Card>
                <List orientation="horizontal" sx={{ gap: 1 }}>
                    <ListItem>
                        <ListItemButton component={Link} to="#teams">
                            <ListItemText>Adicionar cargo</ListItemText>
                            <ListItemIcon>
                                <AddCircle />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />

                <Grid container columnGap={2}>
                    <Grid item xs>
                        <List disablePadding>
                            {data?.map(item => (
                                <React.Fragment key={`cat_${item.id}`}>
                                    <ListItem>
                                        <ListItemButton selected={selected?.id == item.id} component={Link} to={`#teams|${item.id}`}>
                                            <ListItemText>{item?.name}</ListItemText>
                                            <ListItemIcon></ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                    </Grid>
                </Grid>

                <DialogTeam mutate={mutate} />
            </Card>
        </Container>
    );
}
