import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import { useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Search from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';

export default function Address({ onChange, name, value, address, setFieldError, setFieldValue }) {
    const [searchingZipCode, setSearching] = useState(false);
    const searchPostalCode = useCallback(
        async e => {
            try {
                setSearching(true);
                if (String(value).length === 8) {
                    const { data } = await axios.get(`https://viacep.com.br/ws/${value}/json/`);

                    if (data.erro) throw 'Erro';

                    setFieldValue('street', data.logradouro);
                    setFieldValue('neighborhood', data.bairro);
                    setFieldValue('city', data.localidade);
                    setFieldValue('state', data.uf);

                    setFieldValue('address', `${data.logradouro}, ${data.bairro}, ${data.localidade}-${data.uf}`);

                    setSearching(false);
                }
            } catch (error) {
                setSearching(false);
                setFieldError('zipcode', 'CEP Inválido');
            }
        },
        [value],
    );

    return (
        <div>
            <TextField
                fullWidth
                label="Digite o cep"
                onChange={onChange}
                value={value}
                name={name}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={e => searchPostalCode()}>
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {Boolean(address) && <FormHelperText>{address}</FormHelperText>}
        </div>
    );
}
