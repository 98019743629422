import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import { useFetch } from '../../hooks/useFecth';
import { Link, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVert from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import { LoadingContent } from '../../components/Loading';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PercentIcon from '@mui/icons-material/Percent';
import StorefrontIcon from '@mui/icons-material/Storefront';
import useDialog from '../../hooks/useDialog';
import Dropdown from '../../components/Dropdown';
import { CardContent } from '@mui/material';
import DialogClub from './components/DialogClub';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PeopleIcon from '@mui/icons-material/People';
import BannersClub from './components/BannersClub';
import ProductsClub from './components/ProductsClub';
import SettingsClub from './components/SettingsClub';
import ListSubscribers from './components/ListSubscribers';
import ListRescues from './components/ListRescues';

export default function ClubeDetails() {
    const { id } = useParams();
    const [tab, setTab] = useState('#tab-1')
    const { data, error, mutate, isFetching } = useFetch(`club/${id}`);
    
    if (isFetching) return <LoadingContent />;
    if (error) return <>Error: {error?.message}</>;
    if (!data) return <>Tente novamente mais tarde</>;

    return (
        <Container sx={{ gap: 3, flexDirection: 'column', display: 'flex', pb:4 }}>
            <Box pt={5}>
                <Typography variant="h2">Detalhes do clube</Typography>
            </Box>

            <Card>
                <CardContent sx={{rowGap:2, display: 'flex', flexDirection: 'column'}}>
                    <Stack direction="row" alignItems="center" columnGap={2}>
                        <Box
                            component="img"
                            width={60}
                            height={60}
                            sx={{ borderRadius: 50, border: '1px solid #ccc', objectFit: 'cover' }}
                            src={data.image}
                        />
                        <div>
                            <Typography variant="h4">{data.name}</Typography>
                            <Typography variant="h6" sx={{ fontStyle: 'italic' }}>
                                {data.businessName}
                            </Typography>
                        </div>

                        <Stack direction="row" gap={1} sx={{ ml: 'auto' }}>
                            <Dropdown>
                                <IconButton>
                                    <MoreVert />
                                </IconButton>
                                <Menu placement="bottom-start">
                                    <MenuItem component={Link} to={`#club|${data.id}`} >Alterar dados</MenuItem>
                                    <Divider />
                                    <MenuItem color="danger">Excluir</MenuItem>
                                </Menu>
                            </Dropdown>
                        </Stack>
                    </Stack>
                    <Divider />
                    <Grid container columnGap={2}>
                        <Grid item xs={12} sm={6} md textAlign='center'>
                            <Typography level="title-sm">Status</Typography>
                            <Chip
                                    size="small"
                                    label={data.status ? 'Ativo' : 'Inativo'}
                                    color={data.status ? 'success' : 'error'}
                                    icon={<FiberManualRecordIcon htmlColor="green" />}
                                />
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} sm={6} md>
                            <Typography level="title-sm">Plano</Typography>
                            <Typography> Plano Gratuito </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        {/* <Grid item xs={12} sm={6} md>
                        <Typography level="title-sm">Tipo</Typography>
                        <Typography>João Paulo</Typography>
                    </Grid> 
                    <Divider orientation="vertical" flexItem />*/}
                        <Grid item xs={12} sm={6} md>
                            <Typography level="title-sm">Responsável</Typography>
                            <Typography>{data.responsibleName || '--'} </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} sm={6} md>
                            <Typography level="title-sm">Contato</Typography>
                            <Typography> {data.responsibleEmail || '--'} </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <TabContext value={tab}>
                <TabList onChange={(e, tab) => setTab(tab)}>
                    <Tab icon={<PeopleIcon/>} iconPosition="start" value="#tab-1" label="Assinantes" />
                    <Tab icon={<StorefrontIcon />} iconPosition="start" value="#tab-2"  label="Planos" />
                    <Tab icon={<PercentIcon />} iconPosition="start" value="#tab-3"  label="Resgates" />
                    <Tab icon={<ViewCarouselIcon />} iconPosition="start" value="#tab-4"  label="Banners" />
                    <Tab icon={<SettingsApplicationsIcon />} value="#tab-5"  iconPosition="start" label="Configurações" />
                    {/* <Tab icon={<ReceiptIcon />} value="#tab-6"  iconPosition="start" label="Assinatura" /> */}
                </TabList>
                <TabPanel value="#tab-1" sx={{p:0}}>
                    <ListSubscribers />
                </TabPanel>
                <TabPanel value="#tab-2"  sx={{p:0}}>
                    <ProductsClub />
                </TabPanel>
                <TabPanel value="#tab-3"  sx={{p:0}}>
                    <ListRescues  />
                </TabPanel>
                <TabPanel value="#tab-4" sx={{p:0}}>
                    <BannersClub />
                </TabPanel>
                <TabPanel value="#tab-5"  sx={{p:0}}>
                    <SettingsClub data={data} />
                </TabPanel>
            </TabContext>

            <DialogClub mutate={mutate} />
        </Container>
    );
}
