import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import useErrors from '../../../hooks/useErrors';
import server from '../../../services/server';
import { useSnackbar } from 'notistack';
import SelectTeam from '../../../components/SelectTeam';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import { useAuth } from '../../../context/AuthContext';
import { useCookies } from 'react-cookie';
import { useFetch } from '../../../hooks/useFecth';
import useDialog from '../../../hooks/useDialog';
import { Dialog, Divider } from '@mui/material';

function Edit({ id, mutate }) {
    const { data } = useFetch(`users/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const [errs, setErrs] = useState();
    const { open, handleClose } = useDialog('#users');
    const [{ user }] = useCookies();
    useErrors(errs);

    const { enqueueSnackbar } = useSnackbar();

    const { values, handleSubmit, setValues, handleChange, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            userName: '',
            roles: [],
            status: true,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`users`, values);
                    enqueueSnackbar(`Usuário ${values.userName} adicionado`);
                }

                if (Boolean(values.id)) {
                    console.log(values);
                    setSubmitting(true);
                    await server.put(`users/${values.id}`, values);
                    enqueueSnackbar(`Usuário ${values.userName} alterado`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            setValues(data, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>{Boolean(data) ? 'Editando usuário' : 'Adicionando usuário'}</DialogTitle>

            {/* <Typography>Vamos adicionar uma nova {Boolean(childOf) ? `subcargo em ${childOf.name}` : 'cargo'}</Typography> */}
            <Divider />
            <DialogContent component="form" onSubmit={handleSubmit}>
                <Stack rowGap={2}>
                    <FormControl>
                        <TextField label="Usuário" onChange={handleChange} placeholder={`Usuário`} value={values.userName} name="userName" />
                        <FormHelperText>User lestras minúsculas sem espaços e sem caracteres especiais</FormHelperText>
                    </FormControl>
                    <TextField
                        label="Senha"
                        onChange={handleChange}
                        type="password"
                        inputProps={{ minLength: 6 }}
                        placeholder={`Senha`}
                        value={values.password}
                        name="password"
                    />
                    <SelectTeam onChange={setFieldValue} disabled={user?.id === values?.id} placeholder="Cargo" name="teamId" value={values.teamId} />
                    <FormControl orientation="horizontal">
                        <FormLabel>Status</FormLabel>
                        <Switch
                            checked={values.status}
                            disabled={user?.id === values?.id}
                            onChange={e => setFieldValue('status', e.target.checked)}
                        />
                    </FormControl>
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant='contained' loading={isSubmitting} onClick={handleSubmit} type="submit">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function DialogUser({ mutate }) {
    const { open, id } = useDialog('#users');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
