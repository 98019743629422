import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import Address from '../../../components/Address';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import server from '../../../services/server';
import { useSnackbar } from 'notistack';
import useErrors from '../../../hooks/useErrors';
import AddImage from '../../../components/AddImage';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, InputLabel, Switch } from '@mui/material';
import useDialog from '../../../hooks/useDialog';
import { useFetch } from '../../../hooks/useFecth';
import SelectCategories from '../../../components/SelectCategories';

function Edit({ id, mutate }) {
    const { data } = useFetch(`advertiser/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const [errs, setErrs] = useState();
    useErrors(errs);
    const { enqueueSnackbar } = useSnackbar();
    const { open, handleClose } = useDialog('#advertiser');

    const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            businessName: '',
            image: '',
            document: '',
            headOfficeId: '',
            zipcode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: '',
            country: '',
            phone: '',
            areacode: '',
            latitude: '',
            longitude: '',
            address: '',
            status: true,
            categoryId: [],
            responsibleName: '',
            responsibleEmail: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`advertiser`, values);
                    enqueueSnackbar(`Anunciante ${values.name} adicionado`);
                }

                if (Boolean(values.id)) {
                    setSubmitting(true);
                    await server.put(`advertiser/${values.id}`, values);
                    enqueueSnackbar(`Anunciante ${values.name} alterado`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            setValues(
                {
                    ...data,
                    address: `${data.street}, ${data.neighborhood}, ${data.city}-${data.state}`,
                    categoryId: data.Advertiser_category.map(i => i.id),
                },
                true,
            );
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Adicionar/editar anunciante</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack gap={2} component="form" onSubmit={handleSubmit}>
                    {Boolean(values.id) && !values.headOfficeId && (
                        <>
                            <AddImage onChange={setFieldValue} value={values.previewImage || values.image} />
                        </>
                    )}
                    <TextField onChange={handleChange} label="Nome do anunciante" name="name" value={values.name} />
                    <TextField onChange={handleChange} label="Razão social" name="businessName" value={values.businessName} />
                    <TextField onChange={handleChange} label="Documento" name="document" value={values.document} />

                    {Boolean(values.id) && !values.headOfficeId && (
                        <>
                            <TextField onChange={handleChange} label="Website" name="website" value={values.website} />
                        </>
                    )}

                    <TextField onChange={handleChange} label="Nome do responsável" name="responsibleName" value={values.responsibleName} />
                    <TextField onChange={handleChange} label="Email do responsável" name="responsibleEmail" value={values.responsibleEmail} />

                    <Address
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        name="zipcode"
                        setFieldError={setFieldError}
                        value={values.zipcode}
                        address={values.address}
                    />
                    <Stack direction="row" columnGap={2}>
                        <TextField fullWidth onChange={handleChange} label="Complemento" name="complement" value={values.complement} />
                        <TextField sx={{ width: 150 }} onChange={handleChange} label="Número" name="number" value={values.number} />
                    </Stack>

                    {Boolean(values.id) && !values.headOfficeId && (
                        <>
                            <SelectCategories value={values.categoryId} name="categoryId" onChange={setFieldValue} label="Selecione as categorias" />
                        </>
                    )}

                    {Boolean(values.id) && (
                        <>
                            <FormControlLabel
                                label="Status anunciante"
                                control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                            />
                        </>
                    )}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar e continuar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function DialogAdvertiser({ mutate }) {
    const { open, id } = useDialog('#advertiser');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
