import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
import { useEffect, useState } from 'react';
import { FormHelperText, TextField } from '@mui/material';

export default function SelectProducts({ value, name, onChange, placeholder, type, label }) {
    const [options, setOptions] = useState([]);
    const { data, mutate, error, isFetching } = useFetch(`products`, { params: { type: type ||'plan', clubId: null } });

    useEffect(() => {
        if (data && data.rows) {
            setOptions(prev => {
                const combinedOptions = [...prev, ...data.rows];
                const uniqueOptions = combinedOptions.reduce((acc, current) => {
                    if (!acc.some(item => item.id === current.id)) {
                        acc.push(current);
                    }
                    return acc;
                }, []);
                return uniqueOptions;
            });
        }
    }, [data]);

    const handleSelect = (selected)=>{
        onChange(name, selected.map( i=>i.id) )
    }
    
    const selected = options?.filter( item=> value && value.includes(item.id) )
    
    return ( 
        <>
            <Autocomplete
                placeholder={placeholder}
                getOptionLabel={e => e?.name || null}
                options={options}
                value={selected || null}
                name={name}
                multiple
                loading={isFetching}
                onChange={(e, newValue) => handleSelect(newValue)}
                renderInput={(params) => <TextField {...params} label={label || "Selecione o plano"} />}            />
            <FormHelperText>{error?.message}</FormHelperText>
        </>
    );
}
