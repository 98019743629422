import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import server from '../../../services/server';
import { useSnackbar } from 'notistack';
import useErrors from '../../../hooks/useErrors';
import AddImage from '../../../components/AddImage';
import { Avatar, Card, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Switch } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import useDialog from '../../../hooks/useDialog';
import { useFetch } from '../../../hooks/useFecth';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SelectProducts from '../../../components/SelectProducts';

function Form({ data, mutate }) {
    const { id: clubId } = useParams();
    const { open, handleClose } = useDialog('#planos');
    const [errs, setErrs] = useState();
    const navigation = useNavigate();
    useErrors(errs);
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm();
    const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            description: '',
            type: 'plan',
            price: '',
            disccount: '',
            image: '',
            clubId: clubId,
            productAdditionalIds: [],
            status: true,
            action: 'create',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    const { data } = await server.post(`club/${clubId}/plans`, { ...values, price: Number(values.price) });
                    navigation(`#planos|${data.data.id}`, { replace: true });
                    enqueueSnackbar(`Plano adicionado`);
                }

                if (Boolean(values.id)) {
                    if (values.action === 'delete') {
                        setSubmitting(true);
                        await server.delete(`club/${clubId}/plans/${values.id}`, values);
                        enqueueSnackbar(`Plano removido`);
                    } else {
                        setSubmitting(true);
                        await server.put(`club/${clubId}/plans/${values.id}`, { ...values, price: Number(values.price) });
                        enqueueSnackbar(`Plano alterado`);
                    }

                    resetForm();
                    handleClose();
                }

                await mutate();
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    async function handleDelete() {
        await confirm({ title: 'Removendo plano', description: 'Você confirma que está removendo o plano?' });
        setFieldValue('action', 'delete');
        handleSubmit();
    }

    useEffect(() => {
        if (data) {
            console.log(data);
            setValues({ ...data, productAdditionalIds: data.ChildProduct.map(i => i.id), action: 'update' }, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Adicionando/editando planos</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    <TextField onChange={handleChange} label={`Nome do plano`} value={values.name} name="name" />
                    {values.id && (
                        <AddImage value={values.previewImage || values.image} onChange={setFieldValue} name="image" label="Adicionar imagem" />
                    )}

                    <TextField onChange={handleChange} label={`Descriçãod o plano`} value={values.description} name="description" />
                    <TextField onChange={handleChange} label="Preço de venda" value={values.price} name="price" />

                    {values.id && (
                        <>
                            <SelectProducts
                                name="productAdditionalIds"
                                value={values.productAdditionalIds}
                                onChange={setFieldValue}
                                type="additional"
                                label="Selecione os produtos adicionais"
                            />
                            <FormControlLabel
                                control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                                orientation="horizontal"
                                label="Status"
                            />
                        </>
                    )}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                {!!values.id && (
                    <Button color="error" variant="outlined" onClick={handleDelete}>
                        Excluir
                    </Button>
                )}
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Edit({ mutate, id }) {
    const { id: clubId } = useParams();
    const { data } = useFetch(`club/${clubId}/plans/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function DialogContainer({ mutate }) {
    const { id } = useDialog('#planos');

    if (id) return <Edit id={id} mutate={mutate} />;
    return <Form mutate={mutate} />;
}
export default function ProductsClub() {
    const { id } = useParams();
    const { data, mutate } = useFetch(`club/${id}/plans`);

    return (
        <>
            <Paper sx={{p:2}}>
                <Stack pb={2} direction="row" justifyContent="flex-end" alignItems="center" columnGap={2}>
                    <Button component={Link} to="#planos" variant="outlined">
                        Adicionar plano
                    </Button>
                </Stack>
                <Card>
                    <List>
                        {!!data && !Boolean(data.length) && (
                            <ListItem>
                                <ListItemText>Nenhum item</ListItemText>
                            </ListItem>
                        )}
                        {data?.map(item => (
                            <React.Fragment key={item.id}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton component={Link} to={`#planos|${item.id}`}>
                                            <ChevronRightIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemButton component={Link} to={`#planos|${item.id}`}>
                                        <ListItemAvatar>
                                            <Avatar sx={{ borderRadius: 2, width: 90, mr: 3 }} src={item.image} />
                                        </ListItemAvatar>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                </Card>
            </Paper>
            <DialogContainer mutate={mutate} />
        </>
    );
}
