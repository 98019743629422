import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import Address from '../../../components/Address';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import server from '../../../services/server';
import { useSnackbar } from 'notistack';
import useErrors from '../../../hooks/useErrors';
import AddImage from '../../../components/AddImage';
import { Divider, Switch } from '@mui/material';
import SelectPlan from '../../../components/SelectPlan';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import useDialog from '../../../hooks/useDialog';
import { useFetch } from '../../../hooks/useFecth';

function Edit({ id, mutate }) {
    const { data } = useFetch(`club/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const { open, handleClose } = useDialog('#club');
    const [errs, setErrs] = useState();
    useErrors(errs);
    const { enqueueSnackbar } = useSnackbar();

    const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            demo: false,
            description: '',
            document: '',
            zipcode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            goias: '',
            country: 'BR',
            domain: '',
            packageName: '',
            appIntent: '',
            primaryColor: '',
            secundaryColor: '',
            supportPhone: '',
            supportEmail: '',
            planId: '',
            Owner: {
                name: '',
                email: '',
                document: '',
                birthday: '',
                gender: '',
                phone: '',
            },
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`club`, values);
                    enqueueSnackbar(`Anunciante ${values.name} adicionado`);
                }

                if (Boolean(values.id)) {
                    setSubmitting(true);
                    await server.put(`club/${values.id}`, values);
                    enqueueSnackbar(`Anunciante ${values.name} alterado`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            console.log(data);
            setValues({ ...data, address: `${data.street}, ${data.neighborhood}, ${data.city}-${data.state}` }, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Adicionando/editando clube</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack gap={2} sx={{ overflow: 'scroll' }}>
                    {/* {Boolean(values.id) && (
                        <>
                            <AddImage name='logo' onChange={setFieldValue} value={values.previewImage || values.logo} />
                        </>
                    )} */}

                    <TextField
                        label="Nome do responsável pelo clube"
                        onChange={handleChange}
                        placeholder="Nome do dono clube"
                        name="Owner.name"
                        value={values.Owner?.name}
                    />

                    <TextField
                        label="Email do responsável pelo clube"
                        onChange={handleChange}
                        placeholder="Email do dono clube"
                        name="Owner.email"
                        value={values.Owner?.email}
                    />

                    <TextField
                        label="Documento do responsável pelo clube"
                        onChange={handleChange}
                        placeholder="Documento do dono clube"
                        name="Owner.document"
                        value={values.Owner?.document}
                    />

                    <TextField
                        label="Telefone do responsável pelo clube"
                        onChange={handleChange}
                        placeholder="Telefone do clube"
                        name="Owner.phone"
                        value={values.Owner?.phone}
                    />

                    <TextField
                        label="Aniversário do responsável pelo clube"
                        onChange={handleChange}
                        placeholder="Aniversário"
                        name="Owner.birthday"
                        type="date"
                        value={values.Owner?.birthday}
                    />

                    <Divider />

                    <TextField label="Nome do clube" onChange={handleChange} placeholder="Nome do clube" name="name" value={values.name} />

                    {Boolean(values.id) && (
                        <>
                            <TextField
                                label="Razão Social"
                                onChange={handleChange}
                                placeholder="Razão social"
                                name="businessName"
                                value={values.businessName}
                            />

                            <TextField label="Documento" onChange={handleChange} placeholder="Documento" name="document" value={values.document} />
                            <Address
                                onChange={handleChange}
                                setFieldValue={setFieldValue}
                                name="zipcode"
                                setFieldError={setFieldError}
                                value={values.zipcode}
                                address={values.address}
                            />
                            <TextField
                                label="Complemento"
                                onChange={handleChange}
                                placeholder="Complemento"
                                name="complement"
                                value={values.complement}
                            />
                        </>
                    )}

                    {/* <SelectPlan name="planId" value={values.planId} onChange={setFieldValue} /> */}

                    {Boolean(values.id) && (
                        <>
                            <FormControlLabel
                                control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                                orientation="horizontal"
                                label="Status do clube"
                            />
                        </>
                    )}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar e continuar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function DialogClub({ mutate }) {
    const { open, id } = useDialog('#club');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
