import axios from 'axios';
const SERVER = process.env.REACT_APP_SERVER

const server = axios.create({
    baseURL: SERVER,
    timeout: 20000,
    timeoutErrorMessage: 'Servidor Instável. Tente mais tarde.'
});

server.interceptors.response.use(
    response => response,
    error => {
        throw error;
    },
);

export default server;
