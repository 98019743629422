import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAuth } from '../context/AuthContext';
import { useCookies } from 'react-cookie';
import { useApp } from '../context/AppContext';
import HelpSidebarProvider from '../context/HelpSidebarContext';
import { AppBar, Button, FormControl, LinearProgress, ListItemText, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Dropdown from '../components/Dropdown';
export default function Protected() {
    const navigate = useNavigate();
    const [{ user }] = useCookies();
    const { LogOut, authenticated } = useAuth();
    const { loading } = useApp();
    
    const isMobile = useMediaQuery('(max-width:600px)');

    if (!authenticated) return <>Aguardando autenticação</>;

    return (
        <HelpSidebarProvider>
            <AppBar position="fixed">
                <Toolbar>
                    <Box component="img" src={Logo} width={130} height="auto" sx={{mr:2}} />
                    <Stack direction="row" sx={{width: '100%'}}  columnGap={2} alignItems="center" justifyContent="space-between">
                        <List sx={{ display: isMobile ? 'none' : 'inherit', mr: 'auto' }} variant="horizontal">
                            <ListItemButton component={Link} to="/dashboard">
                                <ListItemText primary="Dashboard" />
                                {/* <ListItemIcon>
                                    <ExpandMore />
                                </ListItemIcon> */}
                            </ListItemButton>
                            <ListItemButton component={Link} to="clubes">
                                <ListItemText primary="Clube" />
                                {/* <ListItemIcon>
                                    <ExpandMore />
                                </ListItemIcon> */}
                            </ListItemButton>
                            <ListItemButton component={Link} to="anunciantes">
                                <ListItemText primary="Anunciantes" />
                                {/* <ListItemIcon>
                                    <ExpandMore />
                                </ListItemIcon> */}
                            </ListItemButton>

                            {/* <ListItemButton onClick={e => navigate('configuracoes')}>
                            Configurações
                            <ListItemIcon>
                                <ExpandMore />
                            </ListItemIcon>
                        </ListItemButton> */}
                        </List>
                        <Stack ml='auto' direction="row" columnGap={1} justifyContent="flex-end" alignContent="center">
                            {/* <FormControl orientation="horizontal" size="sm" sx={{ display: isMobile ? 'none' : 'inherit' }}> */}
                                {/* <IconButton variant='outlined' onClick={e=>setMode(mode === 'light' ? 'dark' : 'light')}>{mode === 'light' ?<DarkModeIcon /> :<LightModeIcon />}</IconButton> */}
                                {/* <FormLabel>{mode === 'dark' ? 'Modo Escuro' : 'Modo Claro'}</FormLabel>
                            <Switch checked={mode === 'dark'} onChange={e => setMode(e.target.checked ? 'dark' : 'light')} /> */}
                            {/* </FormControl> */}
                            <Dropdown>
                                <Button color='inherit' size='small' variant="outlined">
                                    <Box flexDirection="row" display="flex" alignItems="center" gap={2}>
                                        <Typography color="white" variant="caption">
                                            {user.userName}
                                        </Typography>
                                        <Avatar sx={{ width: 25, height: 25 }} />
                                        {/* <ExpandMore /> */}
                                    </Box>
                                </Button>
                                <Menu placement="bottom-end">
                                    {/* <MenuItem onClick={e => navigate('configuracoes/cargos')}>Cargos</MenuItem> */}
                                    <MenuItem onClick={e => navigate('configuracoes/usuarios')}>Usuários</MenuItem>
                                    <MenuItem onClick={e => navigate('configuracoes/categorias')}>Categorias</MenuItem>
                                    <MenuItem onClick={e => navigate('configuracoes/adicionais')}>Adicionais</MenuItem>
                                    <MenuItem onClick={e => navigate('configuracoes/planos')}>Planos</MenuItem>
                                    <Divider />
                                    <MenuItem
                                        onClick={e => {
                                            LogOut();
                                            navigate('/', { replace: true });
                                        }}
                                    >
                                        Sair
                                    </MenuItem>
                                </Menu>
                            </Dropdown>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            {/* <Box position="fixed" zIndex={999} width="100vw">
                <Box px={2}>
                    <Stack direction="row" columnGap={2} py={1} alignItems="center">
                        <Box component="img" src={Logo} width={130} height="auto" />
                        <List sx={{ display: isMobile ? 'none' : 'inherit', mr: 'auto' }} orientation="horizontal"  variant="plain">
                            <ListItemButton>
                                Dashboard
                                <ListItemIcon>
                                    <ExpandMore />
                                </ListItemIcon>
                            </ListItemButton>
                            <ListItemButton onClick={e => navigate('clubes')}>
                                Clubes
                                <ListItemIcon>
                                    <ExpandMore />
                                </ListItemIcon>
                            </ListItemButton>
                            <ListItemButton onClick={e => navigate('anunciantes')}>
                                Anunciantes
                                <ListItemIcon>
                                    <ExpandMore />
                                </ListItemIcon>
                            </ListItemButton>
                            
                            <ListItemButton onClick={e => navigate('configuracoes')}>
                            Configurações
                            <ListItemIcon>
                                <ExpandMore />
                            </ListItemIcon>
                        </ListItemButton>
                        </List>
                        <Stack direction='row' columnGap={1} justifyContent='flex-end' alignContent='center'>
                        <FormControl orientation="horizontal" size="sm" sx={{ display: isMobile ? 'none' : 'inherit' }}>
                            <IconButton variant='outlined' onClick={e=>setMode(mode === 'light' ? 'dark' : 'light')}>{mode === 'light' ?<DarkModeIcon /> :<LightModeIcon />}</IconButton>
                            <FormLabel>{mode === 'dark' ? 'Modo Escuro' : 'Modo Claro'}</FormLabel>
                            <Switch checked={mode === 'dark'} onChange={e => setMode(e.target.checked ? 'dark' : 'light')} />
                        </FormControl>
                        <Dropdown>
                            <Button size="sm" variant="outlined">
                                <Box flexDirection="row" display="flex" alignItems="center" gap={2}>
                                    <Avatar sx={{width:25, height:25}} />
                                    <Typography variant="body1">{user.userName}</Typography>
                                    <ExpandMore />
                                </Box>
                            </Button>
                            <Menu placement="bottom-end">
                                <MenuItem onClick={e => navigate('configuracoes/adicionais')}>Adicionais</MenuItem>
                                <MenuItem onClick={e => navigate('configuracoes/planos')}>Planos</MenuItem>
                                <MenuItem onClick={e => navigate('configuracoes/categorias')}>Categorias</MenuItem>
                                <MenuItem onClick={e => navigate('configuracoes/cargos')}>Cargos</MenuItem>
                                <MenuItem onClick={e => navigate('configuracoes/usuarios')}>Usuários</MenuItem>
                                <Divider />
                                <MenuItem
                                    onClick={e => {
                                        LogOut();
                                        navigate('/', { replace: true });
                                    }}
                                >
                                    Sair
                                </MenuItem>
                            </Menu>
                        </Dropdown>
                        </Stack>
                    </Stack>
                </Box>
                <LinearProgress size="sm" determinate={!loading} variant="soft" />
            </Box>
            <Divider /> */}

            <Paper sx={{ minHeight: 'calc(100vh - 60px)', paddingTop: '60px' }}>
                <Outlet />
            </Paper>
        </HelpSidebarProvider>
    );
}
