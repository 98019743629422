import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import { useFetch } from '../../hooks/useFecth';
import { Link, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVert from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import { LoadingContent } from '../../components/Loading';
import ListOffers from './components/ListOffers';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PercentIcon from '@mui/icons-material/Percent';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ListBranches from './components/ListBranches';
import DialogAdvertiser from './components/DialogAdvertiser';
import Dropdown from '../../components/Dropdown';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import useDialog from '../../hooks/useDialog';
import { Card, CardContent, ListItem } from '@mui/material';
import { useState } from 'react';

export default function AdvertiserDetails() {
    const { id } = useParams();
    const { data, error, mutate, isFetching } = useFetch(`advertiser/${id}`);

    const [tab, setTab] = useState('#tab-1')
    const { name, handleOpen } = useDialog();

    if (isFetching) return <LoadingContent />;
    if (error) return <>Error: {error?.message}</>;
    if (!data) return <>Tente novamente mais tarde</>;

    return (
        <>
            <Container sx={{ gap: 3, flexDirection: 'column', display: 'flex' }}>
                <Box pt={5}>
                    <Typography variant="h2">Detalhes do anunciante</Typography>
                </Box>

                <Card>
                    <CardContent>
                        <Stack direction="row" alignItems="center" columnGap={2} pb={2}>
                            <Box
                                component="img"
                                width={60}
                                height={60}
                                sx={{ borderRadius: 50, border: '1px solid #ccc', objectFit: 'cover' }}
                                src={data.image}
                            />
                            <div>
                                <Typography variant="h4">{data.name}</Typography>
                                <Typography variant="h6" sx={{ fontStyle: 'italic' }}>
                                    {data.businessName}
                                </Typography>
                            </div>

                            <Stack direction="row" gap={1} sx={{ ml: 'auto' }}>
                                <Dropdown>
                                    <IconButton>
                                        <MoreVert />
                                    </IconButton>
                                    <Menu placement="bottom-end">
                                        <MenuItem component={Link} to={`#advertiser|${id}`}>
                                            Alterar dados
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem color="danger">Excluir anunciante</MenuItem>
                                        <Divider />
                                        <ListItem color="danger" sx={{fontSize:10}}>ID Legado: {data.originId}</ListItem>
                                    </Menu>
                                </Dropdown>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Grid container columnGap={2} pt={2}>
                            <Grid item xs={12} sm={6} md textAlign="center">
                                <Typography variant="title-sm">Status</Typography>
                                <div>
                                <Chip
                                    size="small"
                                    label={data.status ? 'Ativo' : 'Inativo'}
                                    color={data.status ? 'success' : 'error'}
                                    icon={<FiberManualRecordIcon htmlColor="green" />}
                                />
                                </div>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} sm={6} md>
                                <Typography variant="title-sm">Plano</Typography>
                                <Typography variant="subtitle-sm"> {data.plan? data.plan.name : '--'} </Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} sm={6} md>
                                <Typography variant="title-sm">Tipo</Typography>
                                <Typography variant="subtitle-sm"> -- </Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} sm={6} md>
                                <Typography variant="title-sm">Responsável</Typography>
                                <Typography variant="subtitle-sm">{data?.responsibleName || '--'}</Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={12} sm={6} md>
                                <Typography variant="title-sm">Contato</Typography>
                                <Typography variant="subtitle-sm">{data?.responsibleEmail || '--'}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <TabContext value={tab}>
                    <TabList onChange={(e, tab)=>setTab(tab)}>
                        <Tab icon={<PercentIcon />} iconPosition="start" label="Ofertas" value="#tab-1" />

                        {/* <Tab icon={<StorefrontIcon />} iconPosition="start" label="Produtos" value="#tab-2" /> */}

                        <Tab icon={<CorporateFareIcon />} iconPosition="start" label="Filiais" value="#tab-3" />
                    </TabList>
                    <TabPanel value="#tab-1" sx={{ p: 0 }}>
                        <ListOffers />
                    </TabPanel>
                    <TabPanel value="#tab-2" sx={{ p: 0 }}>

                    </TabPanel>
                    <TabPanel value="#tab-3" sx={{ p: 0 }}>
                        <ListBranches />
                    </TabPanel>
                </TabContext>

                <DialogAdvertiser mutate={mutate} />
            </Container>
        </>
    );
}
