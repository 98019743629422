import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
import { TextField } from '@mui/material';
 
export default function SelectTEam({ value,disabled, name, onChange, placeholder, label }) {
    const { data, mutate, error, isFetching } = useFetch(`teams`, {params:{status: true}});
    if(isFetching) return null
    const selected = data?.filter( item=> value === item?.id)
    
    return (
        <>
            <Autocomplete
                placeholder={placeholder}
                getOptionLabel={e => e?.name || null}
                options={data || []}
                value={selected[0] || null}
                name={name}
                disabled={disabled}
                onChange={(e, newValue) => onChange(name, newValue?.id)}
                renderInput={(params) => <TextField {...params} label={placeholder || label} />}

            />
        </>
    );
}
