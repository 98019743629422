import TextField from '@mui/material/TextField';
import { replace, useFormik } from 'formik';
import Address from '../../../components/Address';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import server from '../../../services/server';
import { useSnackbar } from 'notistack';
import useErrors from '../../../hooks/useErrors';
import AddImage from '../../../components/AddImage';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Help from '@mui/icons-material/Help';
import { useHelper } from '../../../context/HelpSidebarContext';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog, useMediaQuery, Grid, Stack, FormControlLabel, DialogActions } from '@mui/material';
import useDialog from '../../../hooks/useDialog';
import { useFetch } from '../../../hooks/useFecth';

function Edit({ id, mutate }) {
    const { id: advertiseId } = useParams();
    const { data } = useFetch(`offer/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const { id } = useParams();
    const { open, handleClose } = useDialog('#offer');
    const setErrs = useErrors();
    const { enqueueSnackbar } = useSnackbar();
    const { openHelp } = useHelper();
    const navigation = useNavigate();

    const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            name: '',
            description: '',
            rules: '',
            image: '',
            megaBanner: '',
            isTracked: false,
            avaliableQty: '',
            offerChannel: 'physical',
            isCoupon: false,
            isCashback: false,
            isPercentual: false,
            value: '',
            label: '',
            urlOpenExternal: false,
            url: '',
            file: '',
            product_id: '',
            campaignId: '',
            expiration: '',
            status: true,
            advertiseId: id,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    const { data } = await server.post(`offer`, { ...values, value: Number(values.value) });
                    enqueueSnackbar(`Oferta ${values.name} adicionado`);
                    navigation(`#offer|${data.data.id}`, { replace: true });
                }

                if (Boolean(values.id)) {
                    setSubmitting(true);
                    await server.put(`offer/${values.id}`, { ...values, value: Number(values.value) });
                    enqueueSnackbar(`Oferta ${values.name} alterado`);
                    handleClose();
                }

                await mutate();
                setSubmitting(false);
            } catch (error) {
                console.log(error);
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            setValues(data, true);
        }
    }, [data]);

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
            <DialogTitle>Adicionando oferta</DialogTitle>
            <Divider />
            <DialogContent sx={{ rowGap: 2 }} component="form" onSubmit={handleSubmit}>
                <Stack rowGap={2}>
                    {Boolean(values.id) && (
                        <>
                            {/* <AddImage
                                onChange={setFieldValue}
                                name="image"
                                value={values.previewImage || values.image}
                                label="Dimensões 200x100 px"
                            /> */}
                            {/* <AddImage
                                onChange={setFieldValue}
                                name="megaBanner"
                                value={values.previewMegaBanner || values.megaBanner}
                                label="Dimensões recomendadas 500x200px"
                            /> */}
                        </>
                    )}
                    <TextField onChange={handleChange} label="Titulo da oferta" name="name" value={values.name} />
                    <FormControl>
                        <InputLabel size="small">Tipo de oferta</InputLabel>
                        <Select label="Tipo de oferta" name="offerChannel" defaultValue="virtual" value={values.offerChannel} onChange={handleChange}>
                            <MenuItem value="physical">Físico</MenuItem>
                            <MenuItem value="virtual">Virtual</MenuItem>
                        </Select>
                    </FormControl>
                    {values.offerChannel === 'virtual' && (
                        <>
                            <TextField required onChange={handleChange} label="Site da oferta" name="url" value={values.url} />
                            <FormControlLabel
                                label="O site abre fora do app?"
                                control={
                                    <Switch checked={values.urlOpenExternal} onChange={e => setFieldValue('urlOpenExternal', e.target.checked)} />
                                }
                            />
                            {/* <FormControl orientation="horizontal">
                                        <InputLabel size="small">
                                            O site abre fora do app
                                            <IconButton size="small" onClick={e => openHelp('offer_urlOpenExternal')}>
                                                <Help />
                                            </IconButton>
                                        </InputLabel>
                                        
                                    </FormControl> */}
                        </>
                    )}
                    <TextField
                        rows={3}
                        multiline
                        onChange={handleChange}
                        minRows={5}
                        label="Descrição da oferta"
                        name="description"
                        value={values.description}
                    />

                    {/* <InputLabel size="small">
                                Regras
                                <IconButton size="small" onClick={e => openHelp('offer_rules')}>
                                    <Help />
                                </IconButton>
                            </InputLabel> */}
                    <TextField minRows={2} multiline onChange={handleChange} label="Regras" name="rules" value={values.rules} />

                    {/* <InputLabel size="small">
                                Quantidade disponível
                                <IconButton size="small" onClick={e => openHelp('offer_avaliableQty')}>
                                    <Help />
                                </IconButton>
                            </InputLabel> */}
                    <TextField type="number" onChange={handleChange} label="Quantidade disponível" name="avaliableQty" value={values.avaliableQty} />
                    <TextField onChange={handleChange} label="Label" name="label" value={values.label} />
                    <TextField onChange={handleChange} label="Valor do desconto" name="value" value={values.value} />

                    <FormControlLabel
                        control={<Switch checked={values.isTracked} onChange={e => setFieldValue('isTracked', e.target.checked)} />}
                        label="Oferta trackeada"
                    />

                    <FormControlLabel
                        control={<Switch checked={values.isCashback} onChange={e => setFieldValue('isCashback', e.target.checked)} />}
                        label="Cashback?"
                    />

                    <FormControlLabel
                        control={<Switch checked={values.isCoupon} onChange={e => setFieldValue('isCoupon', e.target.checked)} />}
                        label="Cupom?"
                    />

                    {Boolean(values.id) && (
                        <>
                            <FormControlLabel
                                control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                                label="Status da oferta"
                            />
                        </>
                    )}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" onClick={handleSubmit}>
                    Salvar e continuar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function DialogOffer({ mutate }) {
    const { open, id } = useDialog('#offer');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
