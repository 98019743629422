import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useFetch } from '../../../hooks/useFecth';
import Search from '@mui/icons-material/Search';
import { useCallback, useState } from 'react';
import DataGridJoy from '../../../components/DataGridJoy';
import EditIcon from '@mui/icons-material/Edit';
import { Chip, Divider, Paper } from '@mui/material';
import { channel } from '../../../services/helpers';
import DialogOffer from './DialogOffer';

export default function ListOffers() {
    const { id } = useParams();
    const [searchParams, setParams] = useSearchParams();
    const { data, error, mutate, isFetching } = useFetch(`offer`, { params: {advertiserId: id, ...Object.fromEntries(searchParams)} });

    console.log(data)
    
    const handleChangePage = useCallback(
        value => {
            console.log(searchParams, 'page');
            setParams({ ...Object.fromEntries(searchParams), page: value });
        },
        [searchParams],
    );

    const handleChangeRowsPerPage = useCallback(
        value => {
            console.log(searchParams, 'perPage');
            setParams({ ...Object.fromEntries(searchParams), perPage: value });
        },
        [searchParams],
    );

    return (
        <Paper sx={{ p: 2 }}>
            <Stack direction="row" gap={1} pb={2} alignItems="center">
                <Box py={1}>
                    <Typography level="h4">Ofertas do anunciante</Typography>
                </Box>
                <Stack direction="row" gap={1} alignItems="center" sx={{ ml: 'auto' }}>
                    <Button variant="outlined" component={Link} to="#offer">
                        Adicionar oferta
                    </Button>
                    <IconButton variant="outlined">
                        <Search />
                    </IconButton>
                </Stack>
            </Stack>
            <Divider />
            <DataGridJoy
                rows={data?.rows || []}
                page={data?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={data?.rowsPerPage}
                numRows={data?.numRows}
                columns={[
                    {
                        field: 'name',
                        headerName: 'Titulo',
                        flex: 1,
                    },
                    {
                        field: 'offerChannel',
                        headerName: 'Canal',
                        render: ({ value }) => <Chip variant="outlined" label={channel[value]} />,
                    },
                    {
                        field: 'isCashback',
                        headerName: 'Cashback',
                        type: 'boolean',
                        width: 120,
                        align: 'center',
                    },
                    {
                        field: 'createdAt',
                        headerName: 'Criada em',
                        type: 'datetime',
                    },
                    {
                        field: 'status',
                        headerName: 'Status',
                        type: 'boolean',
                        width: 120,
                    },
                    {
                        field: 'action',
                        headerName: '#',
                        type: 'action',
                        width: 50,
                        render: ({ row }) => (
                            <>
                                <IconButton size="sm" variant="outlined" component={Link} to={`#offer|${row.id}`}>
                                    <EditIcon />
                                </IconButton>
                            </>
                        ),
                    },
                ]}
            />

            <DialogOffer mutate={mutate} />
        </Paper>
    );
}
