import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircle from '@mui/icons-material/AddCircle';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useFetch } from '../../hooks/useFecth';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, ListItemText, Pagination, Paper, Stack } from '@mui/material';
import DialogClub from './components/DialogClub';
import DialogMigrate from '../DialogMigrate';

export default function Club() {
    const navigation = useNavigate();
    const { data, error, mutate, isFetching } = useFetch('club');
    const [searchParams, setParams] = useSearchParams();

    const handlePage = page => {
        setParams({ ...Object.fromEntries(searchParams), page: page });
    };

    return (
        <Container sx={{ my: 4 }}>
            <Stack py={3}>
                <Typography variant="h1">Clubes</Typography>
            </Stack>

            <Paper>
                <Stack direction="row" justifyContent="flex-end" columnGap={1} p={2}>
                    <Button variant="outlined" component={Link} to="#migration|club">
                        Migrar clube
                    </Button>
                    <Button variant="outlined" component={Link} to="#clube">
                        Adicionar clube
                    </Button>
                </Stack>
                <Divider />

                <List size="md">
                    {data?.rows?.map(item => (
                        <ListItem key={`list_${item.id}`}>
                            <ListItemButton onClick={e => navigation(`${item.id}`)}>
                                <ListItemText>{item?.name}</ListItemText>
                                <ListItemIcon></ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Stack direction="row" py={2} justifyContent="center">
                    <Pagination page={data?.page} count={data?.pages} onChange={(e, v) => handlePage(v)} />
                </Stack>
            </Paper>

            <DialogClub mutate={mutate} />
            <DialogMigrate mutate={mutate} from="club" />
        </Container>
    );
}
