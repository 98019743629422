import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircle from '@mui/icons-material/AddCircle';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useFetch } from '../../hooks/useFecth';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Chip, Icon, IconButton, ListItemText, Pagination, PaginationItem, Stack } from '@mui/material';
import DialogAdvertiser from './components/DialogAdvertiser';
import Edit from '@mui/icons-material/Edit';
import DialogMigrate from '../DialogMigrate';
import { ChevronRight } from '@mui/icons-material';
export default function Advertiser() {
    const [searchParams, setParams] = useSearchParams();

    const { data, error, mutate, isFetching } = useFetch('advertiser', { params: { ...Object.fromEntries(searchParams) } });
    const handlePage = page => {
        setParams({ ...Object.fromEntries(searchParams), page: page });
    };

    return (
        <Container sx={{ my: 4 }}>
            <Stack py={3}>
                <Typography variant="h1">Anunciantes</Typography>
            </Stack>

            <Card>
                {/* <List orientation="horizontal">
                    <ListItem>
                        <ListItemButton component={Link} to="#advertiser" >
                            <ListItemText>Adicionar anunciante</ListItemText>
                            <ListItemIcon>
                                <AddCircle />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List> */}
                <Stack direction="row" justifyContent="flex-end" columnGap={1} p={2}>
                    <Button variant="outlined" component={Link} to="#migration|advertiser">
                        Migrar anunciante
                    </Button>
                    <Button variant="outlined" component={Link} to="#advertiser">
                        Adicionar anunciante
                    </Button>
                </Stack>
                <Divider />

                <List size="md" sx={{ gap: 1 }}>
                    <>
                        {!Boolean(data) && !Boolean(data.length) && (
                            <ListItem>
                                <ListItemText primary="Nenhum item listado" />
                            </ListItem>
                        )}

                        {data?.rows?.map(item => (
                            <React.Fragment key={`list_${item.id}`}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton component={Link} to={`${item.id}`} title="Abrir">
                                            <ChevronRight />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText>
                                        <Typography variant="subtitle2">#{item.id}</Typography>
                                        <Typography variant="title-md">{item?.name}</Typography>
                                        <Chip size="small" label={item.status ? 'Ativo' : 'Inativo'} />
                                    </ListItemText>
                                    <ListItemIcon></ListItemIcon>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </>
                </List>
                <Stack direction="row" py={2} justifyContent="center">
                    <Pagination page={data?.page} count={data?.pages} onChange={(e, v) => handlePage(v)} />
                </Stack>
            </Card>
            <DialogAdvertiser mutate={mutate} />
            <DialogMigrate mutate={mutate} />
        </Container>
    );
}
