import React, { useImperativeHandle, useState } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import FormCategory from './components/DialogCategory';
import { useFetch } from '../../hooks/useFecth';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircle from '@mui/icons-material/AddCircle';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import DialogCategory from './components/DialogCategory';
import useDialog from '../../hooks/useDialog';
import { Link } from 'react-router-dom';
import { Button, Collapse, IconButton, ListItemText, Stack } from '@mui/material';
import server from '../../services/server';
import { ArrowDropDownCircle, ChevronRight } from '@mui/icons-material';
export default function Category(props, ref) {
    const { data, error, mutate, isFetching } = useFetch('category');
    const { open } = useDialog('#category');
    const [opened, setOpened] = useState();

    const migrationCategory = async () => {
        await server.post(`migration/category`);
        await mutate();
    };

    return (
        <Container>
            <Box py={5}>
                <Typography variant="h2">Categorias</Typography>
            </Box>
            <Card>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" columnGap={1} p={2}>
                    <Button variant="outlined" onClick={migrationCategory}>
                        Migrar categorias
                    </Button>
                    <Button variant="outlined" component={Link} to="#category">
                        Adicionar categoria
                    </Button>
                </Stack>
                <Divider />

                <Grid container columnGap={2}>
                    <Grid item xs>
                        <List size="md" sx={{ gap: 1 }}>
                            <>
                                {!Boolean(data) && !Boolean(data.length) && (
                                    <ListItem>
                                        <ListItemText primary="Nenhum item listado" />
                                    </ListItem>
                                )}
                                {data?.map(item => (
                                    <React.Fragment key={`cat_${item.id}`}>
                                        <ListItem
                                            secondaryAction={
                                                <IconButton onClick={e => setOpened(item.id)}>
                                                    <ArrowDropDownCircle
                                                        sx={{ animation: 'rotate .2s ease', rotate: `${opened === item.id ? -180 : 0}deg` }}
                                                    />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemButton component={Link} to={`#category|${item.id}`}>
                                                <ListItemText primary={item?.name} />
                                                <ListItemIcon></ListItemIcon>
                                            </ListItemButton>
                                        </ListItem>

                                        {Boolean(item?.SubCategorys) && Boolean(item?.SubCategorys.length) && (
                                            <Collapse in={opened === item.id}>
                                                <Divider />
                                                <List size="md" sx={{ ml: 2, gap: 1 }}>
                                                    {item?.SubCategorys.map(sub => (
                                                        <React.Fragment key={`sub_${sub?.id}`}>
                                                            <ListItem>
                                                                <ListItemButton component={Link} to={`#category|${sub.id}`}>
                                                                    - {sub?.name}
                                                                </ListItemButton>
                                                            </ListItem>
                                                            {Boolean(sub?.SubCategorys) && Boolean(sub?.SubCategorys.length) && (
                                                                <List size="md" sx={{ ml: 2, gap: 1 }}>
                                                                    {sub?.SubCategorys.map(sub3 => (
                                                                        <ListItem key={`sub3_${sub3?.id}`}>
                                                                            <ListItemButton component={Link} to={`#category|${sub3.id}`}>
                                                                                -- {sub3?.name}
                                                                            </ListItemButton>
                                                                        </ListItem>
                                                                    ))}
                                                                </List>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </List>
                                            </Collapse>
                                        )}
                                        <Divider />
                                    </React.Fragment>
                                ))}
                            </>
                        </List>
                    </Grid>
                </Grid>
            </Card>
            <DialogCategory mutate={mutate} />
        </Container>
    );
}
