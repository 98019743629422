import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import Address from '../../../components/Address';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import server from '../../../services/server';
import { useSnackbar } from 'notistack';
import useErrors from '../../../hooks/useErrors';
import AddImage from '../../../components/AddImage';
import { Avatar, Card, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Switch } from '@mui/material';
import SelectPlan from '../../../components/SelectPlan';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import useDialog from '../../../hooks/useDialog';
import { useFetch } from '../../../hooks/useFecth';
import { Link, useParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
function Form({ data, mutate }) {
    const { id } = useParams();
    const { open, handleClose } = useDialog('#banners');
    const [errs, setErrs] = useState();
    useErrors(errs);
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm();
    const { values, handleSubmit, handleChange, setFieldValue, setFieldError, setValues, isSubmitting } = useFormik({
        initialValues: {
            title: '',
            link: '',
            isMobile: '',
            image: '',
            previewImage: '',
            status: true,
            action: 'create',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`club/${id}/banners`, values);
                    enqueueSnackbar(`Banner adicionado`);
                }

                if (Boolean(values.id)) {
                    if (values.action === 'delete') {
                        setSubmitting(true);
                        await server.delete(`club/${id}/banners/${values.id}`, values);
                        enqueueSnackbar(`Banner removido`);
                    } else {
                        setSubmitting(true);
                        await server.put(`club/${id}/banners/${values.id}`, values);
                        enqueueSnackbar(`Banner alterado`);
                    }
                }
                resetForm();
                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    async function handleDelete() {
        await confirm({ title: 'Removendo banner', description: 'Você confirma que está removendo o banner?' });
        setFieldValue('action', 'delete');
        handleSubmit();
    }

    useEffect(() => {
        if (data) {
            setValues({ ...data, action: 'update' }, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Adicionando/editando banners</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack gap={2}>
                    <TextField label="Nome do banner" onChange={handleChange} name="title" value={values.title} />
                    <FormControlLabel
                        control={<Switch checked={values.isMobile} onChange={e => setFieldValue('isMobile', e.target.checked)} />}
                        orientation="horizontal"
                        label="Para o app"
                    />

                    {values.isMobile ? (
                        <AddImage
                            name="image"
                            onChange={setFieldValue}
                            value={values.previewImage || values.image}
                            label="Dimensões obrigatórias: 300x150 pixels"
                            maxWidth={1000}
                            maxHeight={400}
                            canvaWidth={500}
                            canvaHeight={200}
                            colorLabel="error"
                        />
                    ) : (
                        <AddImage
                            name="image"
                            onChange={setFieldValue}
                            value={values.previewImage || values.image}
                            label="Dimensões obrigatórias: 1000x300 pixels"
                            maxWidth={500}
                            maxHeight={200}
                            canvaWidth={500}
                            canvaHeight={150}
                            colorLabel="red"
                        />
                    )}

                    <TextField label="Link" onChange={handleChange} name="link" value={values.link} />

                    {/* <TextField
                        label="Link app"
                        onChange={handleChange}
                        placeholder="Documento do dono clube"
                        name="Owner.document"
                        value={values.Owner?.document}
                    /> */}

                    <FormControlLabel
                        control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                        label="Status do banner"
                    />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                {!!values.id && (
                    <Button color="error" variant="outlined" onClick={handleDelete}>
                        Excluir
                    </Button>
                )}
                <Button variant="contained" type="submit" onClick={handleSubmit} loading={isSubmitting}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function Edit({ mutate, id }) {
    const { id: clubId } = useParams();
    const { data } = useFetch(`club/${clubId}/banners/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function DialogContainer({ mutate }) {
    const { id } = useDialog('#banners');

    if (id) return <Edit id={id} mutate={mutate} />;
    return <Form mutate={mutate} />;
}
export default function BannersClub() {
    const { id } = useParams();
    const { data, mutate } = useFetch(`club/${id}/banners`);

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <Stack pb={2} direction="row" justifyContent="flex-end" alignItems="center" columnGap={2}>
                    <Button component={Link} to="#banners" variant="outlined">
                        Adicionar banner
                    </Button>
                </Stack>
                <Card>
                    <List>
                        {!!data && !Boolean(data.length) && (
                            <ListItem>
                                <ListItemText>Nenhum item</ListItemText>
                            </ListItem>
                        )}
                        {data?.map(item => (
                            <React.Fragment key={item.id}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton component={Link} to={`#banners|${item.id}`}>
                                            <ChevronRightIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemButton component={Link} to={`#banners|${item.id}`}>
                                        <ListItemAvatar>
                                            <Avatar sx={{ borderRadius: 2, width: 90, mr: 3 }} src={item.image} />
                                        </ListItemAvatar>
                                        <ListItemText primary={item.title} secondary={item.isMobile ? 'Aplicativo' : 'Site'} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                </Card>
            </Paper>
            <DialogContainer mutate={mutate} />
        </>
    );
}
