import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, TextField } from '@mui/material';
import useDialog from '../hooks/useDialog';
import { useFormik } from 'formik';
import useErrors from '../hooks/useErrors';
import server from '../services/server';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const route=  {
    advertiser: 'anunciantes',
    club: 'clubes'
}
function Form({mutate}) {
    const setErrors = useErrors();
    const { id:from, open, handleClose } = useDialog('#migration');
    const navigation = useNavigate()
    const {enqueueSnackbar} = useSnackbar()
    const { values, isSubmitting, handleChange, handleSubmit} = useFormik({
        initialValues: {
            id: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const {data} = await server.post(`migration/${from}`, { id: values.id });
                enqueueSnackbar('Migração finalizada')
                navigation(`/dashboard/${route[from]}/${data.data.id}`, {replace: true})
                setSubmitting(false);
                mutate()
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle>Migração</DialogTitle>
                <Divider />
                <DialogContent>
                    <Stack>
                        <TextField label="ID de migração" value={values.id} name="id" type='number' onChange={handleChange} />
                    </Stack>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="contained" disabled={isSubmitting} onClick={handleSubmit}>Migrar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default function DialogMigrate({mutate}) {
    const { open } = useDialog('#migration');

    if (open) return <Form mutate={mutate} />;
}
