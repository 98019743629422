import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import useErrors from '../../../hooks/useErrors';
import server from '../../../services/server';
import { useSnackbar } from 'notistack';
import SelectCategory from '../../../components/SelectCategory';
import { Dialog, Divider, FormControlLabel, Switch } from '@mui/material';
import useDialog from '../../../hooks/useDialog';
import { useFetch } from '../../../hooks/useFecth';

function Edit({ id, mutate }) {
    const { data } = useFetch(`category/${id}`);
    console.log(data)
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const setErrors = useErrors();
    const { open, handleClose } = useDialog('#category');

    const { enqueueSnackbar } = useSnackbar();

    const { values, handleSubmit, setValues, handleChange, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            childOf: '',
            status: false,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`category`, values);
                    enqueueSnackbar(`Categoria ${values.name} adicionada`);
                }

                if (Boolean(values.id)) {
                    console.log(values);
                    setSubmitting(true);
                    await server.put(`category/${values.id}`, values);
                    enqueueSnackbar(`Categoria ${values.name} alterada`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            setValues(data, true);
        }

        // if (childOf) {
        //     setValues({ ...values, childOf: childOf.id  || null});
        // }
    }, [data]);

    return (
        <Dialog open fullWidth maxWidth="sm" onClose={handleClose}>
            <DialogTitle>{Boolean(data) ? 'Editando categoria' : 'Adicionando categoria'}</DialogTitle>
            <Divider />
            {/* <Typography>Vamos adicionar uma nova {Boolean(childOf) ? `subcategoria em ${childOf.name}` : 'categoria'}</Typography> */}
            <DialogContent component="form" onSubmit={handleSubmit}>
                <Stack rowGap={2}>
                    <TextField onChange={handleChange} placeholder={`Nome da categoria`} value={values.name} name="name" />
                    <SelectCategory onChange={setFieldValue} placeholder="Categoria pai" name="childOf" value={values.childOf} />
                    <FormControlLabel
                        control={<Switch checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />}
                        label="Status"
                    />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant="contained" loading={isSubmitting} onClick={handleSubmit} type="submit">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function DialogCategory({ mutate }) {
    const { open, id } = useDialog('#category');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
