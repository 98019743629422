import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFecth';
 
import { TextField, Typography } from '@mui/material';
 
export default function SelectRoles({ value, name, onChange, placeholder, label }) {
    const { data, mutate, error, isFetching } = useFetch(`roles`, {params:{status: true}});

    if(error) return <Typography>Erro ao carregar campo</Typography>
    const selected = data?.filter( item=> value.includes(item?.param)) || []
    
    
    return (
        <>
            <Autocomplete
                multiple={true}
                placeholder={placeholder}
                getOptionLabel={e => e?.param || null}
                options={data || []}
                value={selected || null}
                loading={isFetching}
                loadingText="Carregando cargos"
                name={name}
                onChange={(e, newValue) => onChange(name, newValue.map(i=>i.param))}
                renderInput={(params) => <TextField {...params} label={placeholder || label} />}
            />
        </>
    );
}
