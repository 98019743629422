import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import server from '../../../services/server';
import { useSnackbar } from 'notistack';
import SelectRoles from '../../../components/SelectRoles';
import useDialog from '../../../hooks/useDialog';
import { useFetch } from '../../../hooks/useFecth';
import useErrors from '../../../hooks/useErrors';
import { Dialog, Divider } from '@mui/material';

function Edit({ id, mutate }) {
    const { data } = useFetch(`teams/${id}`);
    return <Form data={data} mutate={mutate} />;
}

function Form({ data, mutate }) {
    const setErrors = useErrors();
    const { open, handleClose } = useDialog('#teams');
    const { enqueueSnackbar } = useSnackbar();

    const { values, handleSubmit, setValues, handleChange, isSubmitting, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            roles: [],
            status: true,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (!Boolean(values.id)) {
                    setSubmitting(true);
                    await server.post(`teams`, values);
                    enqueueSnackbar(`Cargo ${values.name} adicionado`);
                }

                if (Boolean(values.id)) {
                    console.log(values);
                    setSubmitting(true);
                    await server.put(`teams/${values.id}`, values);
                    enqueueSnackbar(`Cargo ${values.name} alterado`);
                }

                await mutate();
                setSubmitting(false);
                handleClose();
            } catch (error) {
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    useEffect(() => {
        if (data) {
            setValues(data, true);
        }
    }, [data]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>{Boolean(data) ? 'Editando cargo' : 'Adicionando cargo'}</DialogTitle>
            {/* <Typography>Vamos adicionar uma nova {Boolean(childOf) ? `subcargo em ${childOf.name}` : 'cargo'}</Typography> */}
            <Divider />
            <DialogContent component="form" onSubmit={handleSubmit}>
                <Stack rowGap={2}>
                    <TextField label='Nome do cargo' onChange={handleChange} placeholder={`Nome do cargo`} value={values.name} name="name" />
                    <SelectRoles onChange={setFieldValue} placeholder="Funcões" name="roles" value={values.roles} />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button variant='contained' loading={isSubmitting} onClick={handleSubmit} type="submit">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function DialogTeam({ mutate }) {
    const { open, id } = useDialog('#teams');
    if (open && id) return <Edit id={id} mutate={mutate} />;
    if (open) return <Form mutate={mutate} />;
}
